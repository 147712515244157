@import '../../resources/styles/custom';

#reportDcb {
  #filters {
    .filter {
      margin-left: 0.5rem;
      background: $teliaPurple;
      padding: 0.2rem 0.5rem;
      border-radius: 1rem;
      color: white;
    }
  }
}
