@import '../../resources/styles/custom';

.table {
  display: table;
  margin-bottom: 2rem;
}

.table--row {
  display: table-row;
}

.table--row__header {
  font-weight: bold;
}

.table--cell {
  display: table-cell;
  padding: 0.25rem;
}
