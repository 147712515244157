@import '../../resources/styles/custom';

#permissionsTable {
  .Icon {
    vertical-align: middle;
    font-size: 1.5rem;
  }

  .infoIcon {
    //color: $teliaPurple;
    cursor: help;
  }

  width: auto;

  th,
  td,
  .table--cell {
    padding: 0.5rem;
    text-align: center;
    width: auto;
  }
  th:first-child,
  td:first-child,
  .table--cell:first-child {
    text-align: left;
  }

  td {
    //width: 40px;
    //border-top: 1px solid #dddddd;
    //border-left: 1px solid #dddddd;
    //border-right: 1px solid #dddddd;
    //vertical-align: middle;
    //text-align: center;
  }

  .rotate-45 {
    height: 10rem;
    width: 3.5rem;
    //min-width: 40px;
    //max-width: 40px;
    position: relative;
    vertical-align: bottom;
    padding: 0;
    //font-size: 12px;
    //line-height: 0.8;
  }

  .rotate-45 > div {
    position: relative;
    top: 0px;
    left: 5rem; /* height * tan(45) / 2 = 40 where 80 is the height on the cell and 45 is the transform angle*/
    height: 100%;
    -ms-transform: skew(-45deg, 0deg);
    -moz-transform: skew(-45deg, 0deg);
    -webkit-transform: skew(-45deg, 0deg);
    -o-transform: skew(-45deg, 0deg);
    transform: skew(-45deg, 0deg);
    overflow: hidden;
    //border-left: 1px solid #dddddd;
    //border-right: 1px solid #dddddd;
    //border-top: 1px solid #dddddd;
  }

  .rotate-45 span {
    -ms-transform: skew(45deg, 0deg) rotate(315deg);
    -moz-transform: skew(45deg, 0deg) rotate(315deg);
    -webkit-transform: skew(45deg, 0deg) rotate(315deg);
    -o-transform: skew(45deg, 0deg) rotate(315deg);
    transform: skew(45deg, 0deg) rotate(315deg);
    position: absolute;
    bottom: 30px; /* 40 cos(45) = 28 with an additional 2px margin*/
    left: -25px; /*Because it looked good, but there is probably a mathematical link here as well*/
    display: inline-block;
    // width: 100%;
    width: 85px; /* 80 / cos(45) - 40 cos (45) = 85 where 80 is the height of the cell, 40 the width of the cell and 45 the transform angle*/
    text-align: left;
    // white-space: nowrap; /*whether to display in one line or not*/
  }
}
