@import '../../resources/styles/custom';

.documents-commercial__aggreement {
  margin-bottom: 10px;
}

.documents-commercial__order-form {
  padding-top: 10px;
  margin-bottom: 10px;
}
