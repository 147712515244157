@import 'src/resources/styles/custom';

.mfa--option--row__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
}

.mfa--option--row__icon {
  height: 1.75rem;
}

.mfa--option--row__title {
  margin-left: 0.5rem;
}

.mfa--option--row__subtitle {
  margin-left: 1rem;
  font-style: italic;
}

.mfa--option--row__configured {
  color: $teliaDarkGrey2;
}

.mfa--option--row__unconfigured {
  color: $teliaDisabled;
}

.mfa--option--row__action {
  margin-left: auto;
  min-width: 12rem;
  display: flex;
  justify-content: center;
}

.mfa--setup--secret--key__value {
  line-break: anywhere;
}

.mfa--qr--code__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.mfa--option__sms--tip {
  button {
    margin-left: 0;
    margin-bottom: 0;
  }
}
