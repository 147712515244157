@import 'src/resources/styles/custom';

.accordion-list__label {
  margin-top: 1rem;
  color: $teliaDarkGrey2;
  padding: 0;
}

.accordion-list__right-element {
  float: right;
}
.accordion-list {
  clear: both;
}

.accordion-list.noMarginLeft {
  .accordion {
    margin-left: 0;
  }
}

.accordion-item__description {
  width: 100%;
  padding: 0.5rem 2rem 0;
}

.accordion__header-button {
  display: flex;
  flex-wrap: wrap;
}

.accordion-item__description {
  font-family: 'TeliaSans-Regular';
  font-size: 1rem;
}
