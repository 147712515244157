@import '../../resources/styles/custom';

.tooltip__wrapper {
  background-color: #222222ee;
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.tooltip__heading {
  display: flex;
  color: #fff;
}

.tooltip__row {
  color: #fff;
  font-size: 0.75rem;
}

.tooltip__value {
  padding-left: 1rem;
}

.tooltip__circle {
  border-radius: 0.6em;
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.45rem;
}
