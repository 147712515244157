@import 'src/resources/styles/custom';

.roles__table {
  margin-top: -8.5rem; //To move table closer to the fields above while still allowing the angled brand labels to have space
  border-collapse: collapse;

  .rotate-45 {
    padding-left: -10px;
    white-space: nowrap;
  }

  .rotate-45 > div {
    left: 3.5rem !important;
  }
}

.roles__table--spacing {
  margin-right: -20px;
  height: 10rem;
}

.roles__table--row {
  padding: 0 !important;

  th {
    padding: 0 !important;

    &.label {
      vertical-align: bottom;
    }
  }

  .label {
    color: $teliaDarkGrey2;
  }

  .inactive {
    color: $teliaDisabled;
  }

  td {
    padding: 0 0.2rem !important;
  }

  .roles__table--name {
    min-width: 10.5rem !important;
  }

  .roles__table--tip {
    padding-right: 1.5rem !important;

    .tooltip__button {
      margin-bottom: 0.2rem;
    }
  }
}
