@import 'src/resources/styles/custom';

.label {
  color: $teliaDarkGrey2;
  padding: 0;
}

.field {
  .field__value {
    border-radius: 0.25em;
    border: 1px solid $teliaDarkGrey2;
    color: black;
    font: inherit;
    padding: 10px 15px;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:active,
    &:focus {
      border-color: $teliaCorePurple;
      outline: none;
    }
  }

  .field__value--default {
    color: $teliaDarkGrey;
  }

  .field__input--date,
  .field__input--time {
    margin: -1px 0;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;

    legend {
      padding: 0;
      margin: 0;
      display: contents; // for displaying tooltip in same line
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin: 0.4rem 0 0.8rem 0;
      }
    }
  }

  .tooltip {
    margin-bottom: -1rem;
  }

  textarea {
    overflow: hidden;
    resize: none;
  }

  &.field--displaying {
    .field__value {
      border-color: rgba(0, 0, 0, 0);
    }
  }

  &.field--disabled.field--editing {
    color: $teliaDisabled;
    border-color: $teliaDisabled;

    .field__value {
      color: $teliaDisabled;
      border-color: $teliaDisabled;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $teliaDisabled;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $teliaDisabled;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $teliaDisabled;
    }

    select {
      color: $teliaDisabled;
      border-color: $teliaDisabled;
      background-image: url('./ico_dropArrow_grey.svg');
    }
  }

  &.field--checkbox {
    svg {
      vertical-align: text-bottom;
      margin-right: 0.5rem;
    }
    input {
      position: absolute;
      left: -10;
      opacity: 0;
    }

    .field__value__wrapper {
      display: inline-block;
      /* SVG parts */
      .check-box-with-label__frame {
        fill: none;
        stroke: $teliaDarkGrey2;
        stroke-width: 2px;
        transition: stroke 0.3s ease;
      }
      .check-box-with-label__check-mark {
        fill: none;
        stroke: $teliaCorePurple;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 21px;
        stroke-dashoffset: 21.1px;
        transition: stroke-dashoffset 0.3s ease;
      }

      input:checked + .label .check-box-with-label__check-mark {
        stroke-dashoffset: 0px;
      }
    }

    &.field--editing:not(.field--disabled) {
      cursor: pointer;
      .label {
        cursor: pointer;
      }
      svg {
        cursor: pointer;
      }
      .field__value__wrapper {
        /* SVG parts */
        input:focus + label {
          & .check-box-with-label__frame {
            stroke: $teliaCorePurple;
          }
        }
        &:hover .check-box-with-label__frame {
          stroke: $teliaCorePurple;
        }
      }
    }

    &.field--displaying {
      .check-box-with-label__frame {
        stroke-opacity: 0;
      }

      .field__value__wrapper--unchecked {
        label {
          color: $teliaDisabled;
        }
        .check-box-with-label__cross {
          fill: none;
          stroke: $teliaDisabled;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: stroke-dashoffset 0.3s ease;
        }
      }
    }

    &.field--disabled.field--editing {
      .field__value__wrapper {
        label {
          color: $teliaDisabled;
        }
        .check-box-with-label__frame {
          stroke: $teliaDisabled;
        }
        .check-box-with-label__check-mark {
          stroke: $teliaDisabled;
        }
      }
    }
  }
}

.field--linkTo__icon {
  color: $teliaPurple;
  width: 1.3em;
  vertical-align: bottom;
  margin-left: 1em;
}

.field__value__icon {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  font-size: 1.78rem;
}

.pop-up-line--error {
  border: solid $teliaRed 0.1rem;
  .pop-up-line__container {
    color: $teliaRed !important;
  }
}

.form__column--max {
  max-width: 100%;
}

.form-group {
  background-color: $teliaGrey;
  border: 0;
  //border: 0.1rem solid black;
  padding: 0.5rem 0 0 1rem;
  margin: 1rem 1rem 2rem 0;
  width: fit-content;
}

.form-group--padding {
  padding: 0.5rem 1rem 1rem 1rem;
}

.form__column--double {
  width: 30rem;
  max-width: 30rem;
}

.form--compact {
  :not(div.form--column--margin--normal) {
    .form__column {
      margin-bottom: 0.5rem;
    }
  }
  .field__value {
    padding: 0.1rem 0.2rem;
  }
  .field__value__icon {
    right: 0;
    top: 0.15rem;
  }
  .button {
    padding: 0.5rem 0.6rem;
  }
  .field fieldset {
    ul {
      li {
        margin: 0.1rem 0;
      }
    }
  }
}

.tooltip--show .tooltip__box {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  box-shadow: 0 0 10px 1px white;
}

.tooltip__button:hover,
.tooltip__button:active,
.tooltip__button:focus {
  font-weight: normal !important;
}

.table__cell--min-width {
  width: 1% !important;
}
