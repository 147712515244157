.page-title__title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.page-title__spinner {
  position: relative;
  left: 1rem;
  top: 1rem;
}
