@import '../../resources/styles/custom';

.tooltip {
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;

  &.tooltip--show {
    ul {
      list-style-type: disc;

      li {
        list-style-type: disc;
        margin-left: 1rem;
      }
    }
    span.bold {
      background: none;
    }
  }

  &.tooltip--C {
    $color: $teliaDarkBlue;
    .tooltip__button {
      background-color: $color !important;
      border-color: $color !important;

      &:after {
        border-bottom-color: $color;
      }
    }

    .tooltip__box {
      background-color: $color !important;
      border-color: $color !important;
    }
  }
  &.tooltip--T {
    $color: $teliaDarkGreen;
    .tooltip__button {
      background-color: $color !important;
      border-color: $color !important;

      &:after {
        border-bottom-color: $color;
      }
    }

    .tooltip__box {
      background-color: $color !important;
      border-color: $color !important;
    }
  }
  &.tooltip--S {
    $color: $teliaDarkTeal;
    .tooltip__button {
      background-color: $color !important;
      border-color: $color !important;

      &:after {
        border-bottom-color: $color;
      }
    }

    .tooltip__box {
      background-color: $color !important;
      border-color: $color !important;
    }
  }
  &.tooltip--A {
    $color: $teliaOrange;
    .tooltip__button {
      background-color: $color !important;
      border-color: $color !important;

      &:after {
        border-bottom-color: $color;
      }
    }

    .tooltip__box {
      background-color: $color !important;
      border-color: $color !important;
    }
  }
}
