@import 'src/resources/styles/custom';

.toggle-switch [aria-pressed='true'] {
  background-color: $teliaPurple;
}
.toggle-switch [aria-pressed='false'] {
  transition: background-color 10ms linear;
}

.toggle-switch :before {
  visibility: hidden;
}

.toggle-switch__white {
  border: 1px solid $teliaPurple;
  width: unset;
}

.toggle-switch__button {
  text-decoration: none !important;
}
