@import '../../resources/styles/custom';

.overview-box {
  cursor: pointer;
  padding: 0 !important;

  .overview-box__link {
    display: block;
    padding: 1rem;

    .overview-box__icon .Icon {
      color: $teliaPurple;
      font-size: 3rem;
    }
  }
}

.overview-box:hover {
  color: $teliaPurple !important;
  border-color: $teliaPurple !important;

  .overview-box__icon .Icon {
    color: $teliaPurple !important;
  }

  .heading--level-2 {
    color: $teliaPurple !important;
  }
}

.overview-box--warning {
  border-color: $teliaRed !important;

  .overview-box__icon .Icon {
    color: $teliaRed;
  }
}
