@import '../../resources/styles/custom';

#reportDcbTable {
  th,
  td,
  .full-width-table__cell--sortable {
    &:not(.right) {
      &,
      .button--sortable {
        text-align: left;
      }
    }
  }

  tr.negative {
    td {
      color: $teliaDarkRed;
    }
  }
}
