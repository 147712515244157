.page-subtitle__subtitle {
  margin-bottom: 2rem;
  display: flex;
}

.page-subtitle__spinner {
  position: relative;
  left: 1rem;
  bottom: 0.5rem;
  height: 0px;
}
