@import '../../resources/styles/custom';

.pendingSettlementsList,
.compactSettlementsList {
  .accordion:first-of-type {
    margin-top: 1rem;
  }
}

.pending-settlements__approve-all-form {
  margin-left: 1rem;

  .form__column {
    vertical-align: middle;
    //margin-bottom: 0rem;
  }
}

.settlement__approval {
  .container--small {
    max-width: 100%;
  }
}

.compactSettlementsList {
  .accordion--open .accordion__panel {
    padding: 0;

    .settlement {
      padding: 0;

      .settlement__approval {
        margin: 0;

        .container {
          padding: 0.5rem;
          margin: 0 auto;
        }
      }

      .settlement__aggregate {
        margin: 1rem 0;
      }

      .settlement__aggregate--hidden {
        display: none;
      }
    }
  }
}
