@import '../../resources/styles/custom';

.company-technical__icon--wrapper {
  width: fit-content;
}

.form__row:first-of-type {
  .company-technical__icon--wrapper {
    position: relative;
    top: 22px;
  }
}

.company-technical__address {
  width: 19.5rem;
}

.company-technical__description {
  .field__value {
    white-space: unset;
    overflow-wrap: anywhere;
  }
}

.company-technical__icon {
  cursor: pointer;
  width: 1.5rem;
  vertical-align: middle;
  margin: 0.25rem;
}

.company-technical__icon--delete {
  color: $teliaDarkRed;
}
