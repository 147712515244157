#customerList > * {
  min-height: 4.6rem;
}

.customer-list--wrapper {
  display: table;

  .customer-list {
    display: table-row-group;

    .customer-list__item {
      display: table-row;

      .customer-list__item__company-name {
        display: table-cell;
        white-space: nowrap;
      }

      .customer-list__item__short-numbers {
        display: table-cell;
        .accessNumberOverview {
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
