.settlement__Name_date {
  text-align: right;
  width: 7rem;
  display: inline-block;
  margin-right: 1em;
}
.settlement__name__total {
  text-align: right;
  width: 10rem;
  display: inline-block;
  margin-right: 1em;
}

.settlement__name__id {
  text-align: right;
  width: 8.5rem;
  display: inline-block;
  margin-right: 1em;
}

.settlement__name__contract {
  float: right;
  display: inline-block;
  margin-left: 1em;
}
