.Toastify__toast-container {
  z-index: 9999;
  box-sizing: border-box;
  color: #fff;
  width: 320px;
  padding: 4px;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 9999px);
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  border-radius: 1px;
  justify-content: space-between;
  min-height: 64px;
  max-height: 800px;
  margin-bottom: 1rem;
  padding: 8px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  color: #fff;
  background: #121212;
}

.Toastify__toast--default {
  color: #aaa;
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  flex: auto;
  margin: auto 0;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--default {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: 9999;
  opacity: .7;
  transform-origin: 0;
  background-color: #ffffffb3;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.list__item--block {
  background-position-y: 1rem !important;
}

.list__item--block.list__item--link:hover {
  background-color: #f2f2f2;
  outline: 1px solid #990ae3;
}

.list__item--hover-outline:hover {
  cursor: pointer;
  background-color: #f2f2f2;
  outline: 1px solid #990ae3;
}

.list__item--link > * {
  cursor: pointer;
}

.list__item--block > * {
  padding: .325rem .25rem .325rem 1.25rem;
  text-decoration: none;
  display: block;
}

.list__item--selected {
  outline: 1px solid #990ae3;
}

.number-box--warning {
  background-color: #ff6464;
}

.accordion__header .field {
  float: left;
  margin-right: .5rem;
  display: inline-block;
}

.accordion:not(.accordion--open) .accordion__header-button:focus .field .check-box-with-label__frame, .accordion:not(.accordion--open) .accordion__header-button:active .field .check-box-with-label__frame, .accordion:not(.accordion--open) .accordion__header-button:focus .field .check-box-with-label__check-mark, .accordion:not(.accordion--open) .accordion__header-button:active .field .check-box-with-label__check-mark {
  stroke: #fff;
}

.alert__icon {
  font-size: 2rem;
}

.Icon.Icon--small {
  width: 20px;
  height: 20px;
}

.section__title {
  margin-bottom: .5rem;
}

.list {
  margin-top: 0;
}

.list--links .list__item, .list--links li {
  padding: .25rem 0;
}

.list--link, .list__item--block > * {
  padding: 0 0 0 1.25rem;
}

.list__item, .list li {
  background-position: 0 .4rem !important;
}

.full-width-table__cell {
  margin-left: .5em;
}

.text_inline {
  white-space: nowrap;
}

html {
  font-family: sans-serif;
}

input, textarea {
  background: none;
}

textarea {
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
}

a :hover {
  color: #990ae3;
}

.teliaPurple {
  color: #990ae3 !important;
}

.teliaPurpleBackground {
  background-color: #990ae3;
}

.clickable {
  cursor: pointer;
  transition: all .75s;
}

.clickable:hover {
  background-color: color("grey", "lighten-3");
  transition: all .25s;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right0 {
  right: 0;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.clear {
  clear: both;
}

.multipleLines {
  white-space: pre-line;
}

.bold {
  font-weight: bold;
}

.fullWidth {
  width: 100%;
}

.missing {
  color: #ff2364;
}

.labelPrefix, .longLabelPrefix {
  color: #a0a0a0;
  display: inline-block;
}

.width1rem {
  width: 1rem;
  display: inline-block;
}

.width4rem {
  width: 4rem;
  display: inline-block;
}

.width5rem {
  width: 5rem;
  display: inline-block;
}

.width8rem {
  width: 8rem;
  display: inline-block;
}

.width10rem {
  width: 10rem;
  display: inline-block;
}

.width15rem {
  width: 15rem;
  display: inline-block;
}

.width20rem {
  width: 20rem;
  display: inline-block;
}

.labelPrefix {
  width: 5rem;
}

.longLabelPrefix {
  width: 8rem;
}

.blackText {
  color: #000;
}

.greyText {
  color: #a0a0a0;
}

.redText {
  color: #ff2364;
}

.greenText {
  color: #0c6 !important;
}

.blueText {
  color: #09f !important;
}

.tealText {
  color: #099 !important;
}

.orangeText {
  color: #ff9b00 !important;
}

.darkPurpleText {
  color: #9b009b !important;
}

.lineThrough {
  text-decoration: line-through #8b0000;
}

.rightText {
  text-align: right;
}

.leftText > * {
  text-align: left !important;
}

.centerText {
  text-align: center;
}

.inactive, .inactive .list__link {
  color: #a0a0a0;
}

.rotateUp {
  -webkit-transform: rotate(-90deg);
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.noPadding {
  padding: 0 !important;
}

.paddingTop {
  padding-top: 1rem;
}

.paddingLeft {
  padding-left: 1rem;
}

.noMargin {
  margin: 0;
}

.noMarginTop {
  margin-top: 0;
}

.marginTop {
  margin-top: 1rem;
}

.marginTop2 {
  margin-top: 2rem;
}

.marginTopHalfRem {
  margin-top: .5rem;
}

.marginBottomHalfRem {
  margin-bottom: .5rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

.marginBottom2 {
  margin-bottom: 2rem;
}

.marginLeft {
  margin-left: 1rem;
}

.marginRight {
  margin-right: 1rem !important;
}

.halfRightMargin {
  margin-right: .5rem;
}

.noMarginLeft {
  margin-left: 0 !important;
}

.hideTransition {
  transition: opacity 1s, max-height 1s;
}

.hideIt {
  opacity: 0;
  max-height: 0;
}

.badge {
  color: #fff;
  vertical-align: super;
  background: red;
  border-radius: 50rem;
  margin-left: .25rem;
  padding: .4rem;
  font-size: .8rem;
}

.default, .disabled {
  color: #a0a0a0;
}

.purpleGreyBox {
  background-color: #a0a0a0;
  border: 1px solid #990ae3;
  border-radius: .2rem;
  padding: .2rem .4rem;
  display: inline-block;
  color: #990ae3 !important;
}

.invalidPeriod {
  color: #ff2364 !important;
}

.expiredPeriod {
  color: #ff9b00 !important;
}

.activePeriod {
  color: #0c6 !important;
}

.futurePeriod {
  color: #09f !important;
}

.forbidden {
  color: #ff2364;
  cursor: not-allowed;
  text-decoration: line-through;
}

.unavailable {
  color: #726e6e;
  cursor: not-allowed;
}

.pick {
  cursor: grabbing;
}

.highlight {
  background-color: #0c6;
}

.noWrap {
  white-space: nowrap;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.hidden {
  visibility: hidden;
}

.code {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace !important;
}

.minWidth {
  flex: 0 0 2em !important;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.noHover {
  pointer-events: none;
}

.menu__submenu {
  margin-top: 1px !important;
}

.displayFlex {
  display: flex;
}

.flexGrow {
  flex: 1;
}

.flexBasis10 {
  flex-basis: 10%;
}

.menu__content-panel li:last-child {
  width: 100%;
}

.menu__content-panel li:last-child > button {
  margin-left: auto;
  margin-right: auto;
  padding: 0 .5rem;
}

.noTooltipPadding * > button {
  margin: 0 !important;
}

.envText {
  z-index: 100;
  color: #726e6e;
  position: absolute;
  top: 0;
  left: 5px;
}

@media screen and (width <= 1120px) {
  .envText {
    display: none;
  }
}

.page-title__title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.page-title__spinner {
  position: relative;
  top: 1rem;
  left: 1rem;
}

.support__contact-link {
  margin: 2rem 0 3.5rem;
}

.support__link {
  padding: 0;
}

.section__title {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

.label {
  color: #726e6e;
  padding: 0;
}

.field .field__value {
  color: #000;
  font: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #726e6e;
  border-radius: .25em;
  width: 100%;
  padding: 10px 15px;
  display: block;
  overflow: hidden;
}

.field .field__value:hover, .field .field__value:active, .field .field__value:focus {
  border-color: #990ae3;
  outline: none;
}

.field .field__value--default {
  color: #a0a0a0;
}

.field .field__input--date, .field .field__input--time {
  margin: -1px 0;
}

.field fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.field fieldset legend {
  margin: 0;
  padding: 0;
  display: contents;
}

.field fieldset ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.field fieldset ul li {
  margin: .4rem 0 .8rem;
}

.field .tooltip {
  margin-bottom: -1rem;
}

.field textarea {
  resize: none;
  overflow: hidden;
}

.field.field--displaying .field__value {
  border-color: #0000;
}

.field.field--disabled.field--editing, .field.field--disabled.field--editing .field__value {
  color: #a0a0a0;
  border-color: #a0a0a0;
}

.field.field--disabled.field--editing ::placeholder {
  color: #a0a0a0;
  opacity: 1;
}

.field.field--disabled.field--editing :-ms-input-placeholder {
  color: #a0a0a0;
}

.field.field--disabled.field--editing ::-moz-placeholder {
  color: #a0a0a0;
}

.field.field--disabled.field--editing select {
  color: #a0a0a0;
  background-image: url("ico_dropArrow_grey.3c20d012.svg");
  border-color: #a0a0a0;
}

.field.field--checkbox svg {
  vertical-align: text-bottom;
  margin-right: .5rem;
}

.field.field--checkbox input {
  opacity: 0;
  position: absolute;
  left: -10px;
}

.field.field--checkbox .field__value__wrapper {
  display: inline-block;
}

.field.field--checkbox .field__value__wrapper .check-box-with-label__frame {
  fill: none;
  stroke: #726e6e;
  stroke-width: 2px;
  transition: stroke .3s;
}

.field.field--checkbox .field__value__wrapper .check-box-with-label__check-mark {
  fill: none;
  stroke: #990ae3;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 21;
  stroke-dashoffset: 21.1px;
  transition: stroke-dashoffset .3s;
}

.field.field--checkbox .field__value__wrapper input:checked + .label .check-box-with-label__check-mark {
  stroke-dashoffset: 0;
}

.field.field--checkbox.field--editing:not(.field--disabled), .field.field--checkbox.field--editing:not(.field--disabled) .label, .field.field--checkbox.field--editing:not(.field--disabled) svg {
  cursor: pointer;
}

.field.field--checkbox.field--editing:not(.field--disabled) .field__value__wrapper input:focus + label .check-box-with-label__frame, .field.field--checkbox.field--editing:not(.field--disabled) .field__value__wrapper:hover .check-box-with-label__frame {
  stroke: #990ae3;
}

.field.field--checkbox.field--displaying .check-box-with-label__frame {
  stroke-opacity: 0;
}

.field.field--checkbox.field--displaying .field__value__wrapper--unchecked label {
  color: #a0a0a0;
}

.field.field--checkbox.field--displaying .field__value__wrapper--unchecked .check-box-with-label__cross {
  fill: none;
  stroke: #a0a0a0;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dashoffset .3s;
}

.field.field--checkbox.field--disabled.field--editing .field__value__wrapper label {
  color: #a0a0a0;
}

.field.field--checkbox.field--disabled.field--editing .field__value__wrapper .check-box-with-label__frame, .field.field--checkbox.field--disabled.field--editing .field__value__wrapper .check-box-with-label__check-mark {
  stroke: #a0a0a0;
}

.field--linkTo__icon {
  color: #990ae3;
  vertical-align: bottom;
  width: 1.3em;
  margin-left: 1em;
}

.field__value__icon {
  font-size: 1.78rem;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.pop-up-line--error {
  border: .1rem solid #ff3264;
}

.pop-up-line--error .pop-up-line__container {
  color: #ff3264 !important;
}

.form__column--max {
  max-width: 100%;
}

.form-group {
  background-color: #f2f2f2;
  border: 0;
  width: fit-content;
  margin: 1rem 1rem 2rem 0;
  padding: .5rem 0 0 1rem;
}

.form-group--padding {
  padding: .5rem 1rem 1rem;
}

.form__column--double {
  width: 30rem;
  max-width: 30rem;
}

.form--compact :not(div.form--column--margin--normal) .form__column {
  margin-bottom: .5rem;
}

.form--compact .field__value {
  padding: .1rem .2rem;
}

.form--compact .field__value__icon {
  top: .15rem;
  right: 0;
}

.form--compact .button {
  padding: .5rem .6rem;
}

.form--compact .field fieldset ul li {
  margin: .1rem 0;
}

.tooltip--show .tooltip__box {
  z-index: 1000;
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 10px 1px #fff;
}

.tooltip__button:hover, .tooltip__button:active, .tooltip__button:focus {
  font-weight: normal !important;
}

.table__cell--min-width {
  width: 1% !important;
}

.Icon {
  font-size: inherit;
}

.Icon.rem1 {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.Icon.rem1_25 {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.tooltip {
  font-size: 1rem;
  font-weight: normal;
  display: inline-block;
}

.tooltip.tooltip--show ul {
  list-style-type: disc;
}

.tooltip.tooltip--show ul li {
  margin-left: 1rem;
  list-style-type: disc;
}

.tooltip.tooltip--show span.bold {
  background: none;
}

.tooltip.tooltip--C .tooltip__button {
  background-color: #09f !important;
  border-color: #09f !important;
}

.tooltip.tooltip--C .tooltip__button:after {
  border-bottom-color: #09f;
}

.tooltip.tooltip--C .tooltip__box {
  background-color: #09f !important;
  border-color: #09f !important;
}

.tooltip.tooltip--T .tooltip__button {
  background-color: #0c6 !important;
  border-color: #0c6 !important;
}

.tooltip.tooltip--T .tooltip__button:after {
  border-bottom-color: #0c6;
}

.tooltip.tooltip--T .tooltip__box {
  background-color: #0c6 !important;
  border-color: #0c6 !important;
}

.tooltip.tooltip--S .tooltip__button {
  background-color: #099 !important;
  border-color: #099 !important;
}

.tooltip.tooltip--S .tooltip__button:after {
  border-bottom-color: #099;
}

.tooltip.tooltip--S .tooltip__box {
  background-color: #099 !important;
  border-color: #099 !important;
}

.tooltip.tooltip--A .tooltip__button {
  background-color: #ff9b00 !important;
  border-color: #ff9b00 !important;
}

.tooltip.tooltip--A .tooltip__button:after {
  border-bottom-color: #ff9b00;
}

.tooltip.tooltip--A .tooltip__box {
  background-color: #ff9b00 !important;
  border-color: #ff9b00 !important;
}

.page-subtitle__subtitle {
  margin-bottom: 2rem;
  display: flex;
}

.page-subtitle__spinner {
  height: 0;
  position: relative;
  bottom: .5rem;
  left: 1rem;
}

.documents-commercial__aggreement {
  margin-bottom: 10px;
}

.documents-commercial__order-form {
  margin-bottom: 10px;
  padding-top: 10px;
}

.breadcrumbs {
  margin: 0;
  display: none;
}

.breadcrumbs__breadcrumb {
  background: url("ico_linkarrow_purple.48a9a995.svg") 100% / .6rem no-repeat;
  margin-right: 1rem;
  padding-right: 1.6rem;
  display: inline-block;
}

.breadcrumbs__breadcrumb:last-child {
  background: none;
}

.breadcrumbs__breadcrumb__link {
  opacity: .75;
  cursor: pointer;
  border-bottom: .05rem solid #0000;
}

.breadcrumbs__breadcrumb__link:hover {
  opacity: 1;
  border-bottom: .05rem solid #990ae3;
}

.submenu ul {
  padding-left: 0;
}

.submenu ul li {
  display: inline-block;
}

.submenu__item {
  color: #9b009b;
  opacity: .75;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 2rem;
  transition: border .25s, color .25s, opacity .25s;
}

.submenu__item:hover {
  opacity: 1;
  border-bottom: .1rem solid;
}

.submenu__item--active, .submenu__item--active:hover {
  color: #990ae3;
  opacity: 1;
  border-bottom: .1rem solid;
}

.toggle-switch [aria-pressed="true"] {
  background-color: #990ae3;
}

.toggle-switch [aria-pressed="false"] {
  transition: background-color 10ms linear;
}

.toggle-switch :before {
  visibility: hidden;
}

.toggle-switch__white {
  width: unset;
  border: 1px solid #990ae3;
}

.toggle-switch__button {
  text-decoration: none !important;
}

.button--negative {
  color: #fff;
}

.button--negative:hover {
  color: #e12364;
}

#permissionsTable {
  width: auto;
}

#permissionsTable .Icon {
  vertical-align: middle;
  font-size: 1.5rem;
}

#permissionsTable .infoIcon {
  cursor: help;
}

#permissionsTable th, #permissionsTable td, #permissionsTable .table--cell {
  text-align: center;
  width: auto;
  padding: .5rem;
}

#permissionsTable th:first-child, #permissionsTable td:first-child, #permissionsTable .table--cell:first-child {
  text-align: left;
}

#permissionsTable .rotate-45 {
  vertical-align: bottom;
  width: 3.5rem;
  height: 10rem;
  padding: 0;
  position: relative;
}

#permissionsTable .rotate-45 > div {
  height: 100%;
  position: relative;
  top: 0;
  left: 5rem;
  overflow: hidden;
  transform: skew(-45deg);
}

#permissionsTable .rotate-45 span {
  text-align: left;
  width: 85px;
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: -25px;
  transform: skew(45deg)rotate(315deg);
}

.form__field {
  z-index: 2;
}

.roles__table {
  border-collapse: collapse;
  margin-top: -8.5rem;
}

.roles__table .rotate-45 {
  white-space: nowrap;
  padding-left: -10px;
}

.roles__table .rotate-45 > div {
  left: 3.5rem !important;
}

.roles__table--spacing {
  height: 10rem;
  margin-right: -20px;
}

.roles__table--row, .roles__table--row th {
  padding: 0 !important;
}

.roles__table--row th.label {
  vertical-align: bottom;
}

.roles__table--row .label {
  color: #726e6e;
}

.roles__table--row .inactive {
  color: #a0a0a0;
}

.roles__table--row td {
  padding: 0 .2rem !important;
}

.roles__table--row .roles__table--name {
  min-width: 10.5rem !important;
}

.roles__table--row .roles__table--tip {
  padding-right: 1.5rem !important;
}

.roles__table--row .roles__table--tip .tooltip__button {
  margin-bottom: .2rem;
}

.table {
  margin-bottom: 2rem;
  display: table;
}

.table--row {
  display: table-row;
}

.table--row__header {
  font-weight: bold;
}

.table--cell {
  padding: .25rem;
  display: table-cell;
}

.overview-box {
  cursor: pointer;
  padding: 0 !important;
}

.overview-box .overview-box__link {
  padding: 1rem;
  display: block;
}

.overview-box .overview-box__link .overview-box__icon .Icon {
  color: #990ae3;
  font-size: 3rem;
}

.overview-box:hover {
  color: #990ae3 !important;
  border-color: #990ae3 !important;
}

.overview-box:hover .overview-box__icon .Icon, .overview-box:hover .heading--level-2 {
  color: #990ae3 !important;
}

.overview-box--warning {
  border-color: #ff3264 !important;
}

.overview-box--warning .overview-box__icon .Icon {
  color: #ff3264;
}

.accordion-list__label {
  color: #726e6e;
  margin-top: 1rem;
  padding: 0;
}

.accordion-list__right-element {
  float: right;
}

.accordion-list {
  clear: both;
}

.accordion-list.noMarginLeft .accordion {
  margin-left: 0;
}

.accordion-item__description {
  width: 100%;
  padding: .5rem 2rem 0;
}

.accordion__header-button {
  flex-wrap: wrap;
  display: flex;
}

.accordion-item__description {
  font-family: TeliaSans-Regular;
  font-size: 1rem;
}

.company-technical__icon--wrapper {
  width: fit-content;
}

.form__row:first-of-type .company-technical__icon--wrapper {
  position: relative;
  top: 22px;
}

.company-technical__address {
  width: 19.5rem;
}

.company-technical__description .field__value {
  white-space: unset;
  overflow-wrap: anywhere;
}

.company-technical__icon {
  cursor: pointer;
  vertical-align: middle;
  width: 1.5rem;
  margin: .25rem;
}

.company-technical__icon--delete {
  color: #ff2364;
}

#productsTable {
  margin-top: 2rem;
}

#productsTable tr td {
  cursor: pointer;
}

#productsTable tr:hover td, #productsTable tr:hover td .default {
  color: #990ae3;
}

.settlement {
  border: 1px solid #990ae3;
  padding: 1rem;
  position: relative;
}

.settlement.settlement--pdf {
  padding: 2rem;
  font-size: .95rem;
  border: 0 !important;
}

.settlement.settlement--pending {
  border-color: #ff2364;
}

.settlement.settlement--pending.settlement--adjusting {
  border-style: dashed;
}

.settlement.settlement--pending.settlement--adjusting .settlement__approval .container {
  color: #ff2364;
}

.settlement__approval {
  border-bottom: 1px dashed #ff2364;
  margin: -1rem -1rem 1rem;
}

.settlement__approval .container {
  margin-bottom: 0;
}

.settlement__header {
  margin-bottom: 2rem;
  position: relative;
}

.settlement__logo {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.settlement__logo img {
  width: 7rem;
}

.settlement__fields {
  width: auto;
}

.settlement__field {
  padding: 0 .4rem;
}

.settlement__field--label {
  color: #726e6e;
  padding-right: 1rem;
}

.settlement__fields--total {
  position: absolute;
  bottom: 0;
  right: 0;
}

.settlement__aggregate {
  margin: 3rem 0;
}

.settlement__list {
  padding: 0;
}

.settlement tr:last-of-type {
  border-bottom: 0;
}

.settlement__list th {
  background: #f2f2f2;
}

.settlement__list td, .settlement__list th {
  border-radius: 0;
  margin: 0;
  padding: 0 .4rem;
}

.settlement__list__item--sum {
  border-bottom: 0;
}

.settlement__list__item--sum td, .settlement__list__item--sum th {
  border-top: .1rem solid #a0a0a0;
  padding-bottom: 2rem;
}

.settlement__list__item--total td, .settlement__list__item--total th {
  padding-top: 1.5rem;
  font-weight: bold;
}

.settlement__list__body--padding__bottom > .settlement__list__item:last-child td {
  padding-bottom: 1.5rem;
}

.settlement__list__item--details {
  color: #726e6e;
}

.settlement__list__item--selected {
  background-color: #00ff6440;
}

.settlement__adjustment__icon {
  cursor: pointer;
  vertical-align: middle;
  width: 1.5rem;
  margin: .25rem;
}

.settlement__adjustment__icon--delete {
  color: #ff2364;
}

.settlement__adjustment__icon--undo {
  color: #726e6e;
}

.settlement__adjustment__icon--add, .settlement__adjustment--new {
  color: #990ae3;
}

.settlement__adjustment--deleted {
  color: #ff2364;
  text-decoration: line-through;
}

.settlement__adjustment__buttons {
  margin-top: 1rem;
}

.settlement__list__item--adjusting td {
  vertical-align: top;
}

.settlement__list__item--adjusting .textbox-with-label__input {
  color: #990ae3;
  padding: 0 .5rem;
  border-color: #990ae3 !important;
}

.settlement__list__item--adjusting .settlement__adjustment__producttype {
  width: 11rem;
}

.settlement__list__item--adjusting .settlement__adjustment__fee {
  width: 10rem;
  margin-bottom: auto;
}

.settlement__list__item--adjusting .settlement__adjustment__price {
  float: right;
  width: 6rem;
}

.settlement__list__item--adjusting .settlement__adjustment__price input {
  text-align: right;
}

.settlement__show_details__button {
  cursor: pointer;
  color: #990ae3;
  margin-left: .7rem;
  font-weight: normal;
  text-decoration: underline;
}

@media screen and (width <= 600px) {
  .settlement__logo {
    position: initial;
  }

  .settlement__list .width0-on-small-only {
    visibility: hidden;
    width: 0;
    padding: 0 !important;
  }

  .settlement__list .width0-on-small-only span {
    visibility: hidden;
    width: 0;
    display: block;
  }
}

.settlement__total__sum {
  margin-left: 2.5rem;
}

.settlement__total__sum .settlement__total__sum--label {
  color: #726e6e;
  font-weight: lighter;
}

.settlement__total__sum .settlement__total__sum--value {
  color: #099;
}

.settlement__adjustment_add--tip {
  text-align: left;
  max-width: 230px;
}

.settlement__adjustment_add--tip:after {
  margin-left: 185px;
}

.settlement__Name_date {
  text-align: right;
  width: 7rem;
  margin-right: 1em;
  display: inline-block;
}

.settlement__name__total {
  text-align: right;
  width: 10rem;
  margin-right: 1em;
  display: inline-block;
}

.settlement__name__id {
  text-align: right;
  width: 8.5rem;
  margin-right: 1em;
  display: inline-block;
}

.settlement__name__contract {
  float: right;
  margin-left: 1em;
  display: inline-block;
}

#fileInput {
  display: none;
}

.break-word {
  word-break: break-word;
  white-space: normal;
  max-width: 400px;
}

.product__technical__features__icon--wrapper {
  width: fit-content;
  display: flex;
}

.form__row:first-of-type .product__technical__features__icon--wrapper {
  margin-bottom: 5px;
  margin-top: auto !important;
}

.product__technical__features__icon {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: -5px;
}

.product__technical__features__icon--delete {
  color: #ff2364;
}

.product__technical__features__icon--add {
  color: #09f;
}

#customerList > * {
  min-height: 4.6rem;
}

.customer-list--wrapper {
  display: table;
}

.customer-list--wrapper .customer-list {
  display: table-row-group;
}

.customer-list--wrapper .customer-list .customer-list__item {
  display: table-row;
}

.customer-list--wrapper .customer-list .customer-list__item .customer-list__item__company-name {
  white-space: nowrap;
  display: table-cell;
}

.customer-list--wrapper .customer-list .customer-list__item .customer-list__item__short-numbers {
  display: table-cell;
}

.customer-list--wrapper .customer-list .customer-list__item .customer-list__item__short-numbers .accessNumberOverview {
  margin-right: .5rem;
  display: inline-block;
}

.highlighted {
  background-color: #cdff32;
}

#reportDcb #filters .filter {
  color: #fff;
  background: #990ae3;
  border-radius: 1rem;
  margin-left: .5rem;
  padding: .2rem .5rem;
}

#reportDcbTable th:not(.right), #reportDcbTable th:not(.right) .button--sortable, #reportDcbTable td:not(.right), #reportDcbTable td:not(.right) .button--sortable, #reportDcbTable .full-width-table__cell--sortable:not(.right), #reportDcbTable .full-width-table__cell--sortable:not(.right) .button--sortable {
  text-align: left;
}

#reportDcbTable tr.negative td {
  color: #ff2364;
}

.__json-pretty__ {
  color: #990ae3;
  background: #f2f2f2;
  font-family: monospace;
  line-height: 1.3;
  overflow: auto;
}

.__json-pretty__ .__json-key__ {
  color: #990ae3;
}

.__json-pretty__ .__json-value__, .__json-pretty__ .__json-string__, .__json-pretty__ .__json-boolean__ {
  color: #726e6e;
}

.__json-pretty-error__ {
  color: #748096;
  background: #1e1e1e;
  line-height: 1.3;
  overflow: auto;
}

.tooltip__wrapper {
  background-color: #222e;
  border-radius: .25em;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.tooltip__heading {
  color: #fff;
  display: flex;
}

.tooltip__row {
  color: #fff;
  font-size: .75rem;
}

.tooltip__value {
  padding-left: 1rem;
}

.tooltip__circle {
  border-radius: .6em;
  width: 1.2em;
  height: 1.2em;
  margin-right: .45rem;
}

.pending__info--container {
  display: inline-block;
}

.pendingSettlementsList .accordion:first-of-type, .compactSettlementsList .accordion:first-of-type {
  margin-top: 1rem;
}

.pending-settlements__approve-all-form {
  margin-left: 1rem;
}

.pending-settlements__approve-all-form .form__column {
  vertical-align: middle;
}

.settlement__approval .container--small {
  max-width: 100%;
}

.compactSettlementsList .accordion--open .accordion__panel, .compactSettlementsList .accordion--open .accordion__panel .settlement {
  padding: 0;
}

.compactSettlementsList .accordion--open .accordion__panel .settlement .settlement__approval {
  margin: 0;
}

.compactSettlementsList .accordion--open .accordion__panel .settlement .settlement__approval .container {
  margin: 0 auto;
  padding: .5rem;
}

.compactSettlementsList .accordion--open .accordion__panel .settlement .settlement__aggregate {
  margin: 1rem 0;
}

.compactSettlementsList .accordion--open .accordion__panel .settlement .settlement__aggregate--hidden {
  display: none;
}

.mfa--option--row__wrapper {
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  display: flex;
}

.mfa--option--row__icon {
  height: 1.75rem;
}

.mfa--option--row__title {
  margin-left: .5rem;
}

.mfa--option--row__subtitle {
  margin-left: 1rem;
  font-style: italic;
}

.mfa--option--row__configured {
  color: #726e6e;
}

.mfa--option--row__unconfigured {
  color: #a0a0a0;
}

.mfa--option--row__action {
  justify-content: center;
  min-width: 12rem;
  margin-left: auto;
  display: flex;
}

.mfa--setup--secret--key__value {
  line-break: anywhere;
}

.mfa--qr--code__wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.mfa--option__sms--tip button {
  margin-bottom: 0;
  margin-left: 0;
}
/*# sourceMappingURL=index.94ac8b5e.css.map */
