@import '../../resources/styles/custom';

.submenu {
  ul {
    padding-left: 0;
    li {
      display: inline-block;
    }
  }
}

.submenu__item {
  color: $teliaDarkCorePurple;
  //color: black;
  opacity: 0.75;
  text-transform: uppercase;
  margin-right: 2rem;
  cursor: pointer;
  transition: border ease 0.25s, color ease 0.25s, opacity ease 0.25s;
}

.submenu__item:hover {
  opacity: 1;
  border-bottom: solid 0.1rem;
  //border-bottom: $teliaCorePurple solid 0.1rem;
}

.submenu__item--active,
.submenu__item--active:hover {
  color: $teliaCorePurple;
  opacity: 1;
  //cursor: default;
  border-bottom: solid 0.1rem;
  //border-bottom: $teliaDarkCorePurple solid 0.1rem;
}
