@import '../../resources/styles/custom';

.product__technical__features__icon--wrapper {
  width: fit-content;
  display: flex;
}

.form__row:first-of-type {
  .product__technical__features__icon--wrapper {
    margin-top: auto !important;
    margin-bottom: 5px;
  }
}

.product__technical__features__icon {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: -5px;
}

.product__technical__features__icon--delete {
  color: $teliaDarkRed;
}

.product__technical__features__icon--add {
  color: $teliaDarkBlue;
}
