@import '../../resources/styles/custom';

#productsTable {
  margin-top: 2rem;
}

#productsTable tr td {
  cursor: pointer;
}

#productsTable tr:hover td {
  color: $teliaPurple;

  .default {
    color: $teliaPurple;
  }
}
