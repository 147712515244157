@import '../../resources/styles/custom';

.__json-pretty__ {
  line-height: 1.3;
  font-family: monospace;
  color: $teliaCorePurple;
  background: $teliaGrey;
  overflow: auto;
}

.__json-pretty__ .__json-key__ {
  color: $teliaCorePurple;
}

.__json-pretty__ .__json-value__ {
  color: $teliaDarkGrey2;
}

.__json-pretty__ .__json-string__ {
  color: $teliaDarkGrey2;
}

.__json-pretty__ .__json-boolean__ {
  color: $teliaDarkGrey2;
}

.__json-pretty-error__ {
  line-height: 1.3;
  color: #748096;
  background: #1e1e1e;
  overflow: auto;
}
