@import 'custom';

.list__item--block {
  background-position-y: 1rem !important;

  &.list__item--link:hover {
    background-color: $teliaGrey;
    outline: $teliaCorePurple solid 1px;
  }
}

.list__item--hover-outline:hover {
  cursor: pointer;
  background-color: $teliaGrey;
  outline: $teliaCorePurple solid 1px;
}

.list__item--link > * {
  cursor: pointer;
}

.list__item--block > * {
  display: block;
  padding: 0.325rem 0.25rem 0.325rem 1.25rem;
  text-decoration: none;
}

.list__item--selected {
  outline: $teliaCorePurple solid 1px;
}

.number-box--warning {
  background-color: #ff6464;
}

.accordion__header {
  .field {
    display: inline-block;
    float: left;
    margin-right: 0.5rem;
  }
}

.accordion:not(.accordion--open) .accordion__header-button:focus,
.accordion:not(.accordion--open) .accordion__header-button:active {
  .field {
    .check-box-with-label__frame {
      stroke: white;
    }
    .check-box-with-label__check-mark {
      stroke: white;
    }
  }
}

.pop-up-line--error {
  border: solid $teliaRed 0.1rem;
  .pop-up-line__container {
    color: $teliaRed !important;
  }
}

.tooltip--show .tooltip__box {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  box-shadow: 0 0 10px 1px white;
}

.form__column--max {
  max-width: 100%;
}

.form-group {
  background-color: $teliaGrey;
  border: 0;
  //border: 0.1rem solid black;
  padding: 0.5rem 0 0 1rem;
  margin: 1rem 1rem 2rem 0;
  width: fit-content;

  //> :first-child {
  //  margin-top: -1rem;
  //
  //  span {
  //    background-color: white;
  //  }
  //}

  .section__title {
    //background-color: white;
    //width: fit-content;
  }

  > :last-child {
    //margin-bottom: 0rem;
  }
}

.form-group--padding {
  padding: 0.5rem 1rem 1rem 1rem;
}

.alert__icon {
  font-size: 2rem;
}

.Icon {
  &.Icon--small {
    height: 20px;
    width: 20px;
  }
}

.form__column--double {
  width: 30rem;
  max-width: 30rem;
}

.section__title {
  margin-bottom: 0.5rem;
}
.list {
  margin-top: 0;
}
.list--links .list__item,
.list--links li {
  padding: 0.25rem 0 0.25rem 0;
}
.list--link,
.list__item--block > * {
  padding: 0 0 0 1.25rem;
}
.list__item,
.list li {
  background-position: left 0.4rem !important;
}
#customerList > * {
  min-height: 0;
}

.tooltip__button:hover,
.tooltip__button:active,
.tooltip__button:focus {
  font-weight: normal !important;
}

.table__cell--min-width {
  width: 1% !important;
}

.full-width-table__cell {
  margin-left: 0.5em;
}

.text_inline {
  white-space: nowrap;
}
