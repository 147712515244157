@import 'custom';
@import 'styleguide';

html {
  font-family: sans-serif;
}

input,
textarea {
  background: transparent;
}

textarea {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;

  :hover {
    color: $teliaPurple;
  }
}

.teliaPurple {
  color: $teliaPurple !important;
}

.teliaPurpleBackground {
  background-color: $teliaPurple;
}

.clickable {
  cursor: pointer;
  transition: 0.75s;

  &:hover {
    //@extend .teliaReversedBackground;
    background-color: color('grey', 'lighten-3');
    transition: 0.25s;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right0 {
  right: 0;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.clear {
  clear: both;
}

.multipleLines {
  white-space: pre-line;
}

.bold {
  font-weight: bold;
}

.fullWidth {
  width: 100%;
}

.missing {
  color: $teliaDarkRed;
}

.labelPrefix,
.longLabelPrefix {
  color: $teliaDarkGrey;
  display: inline-block;
}

.width1rem {
  width: 1rem;
  display: inline-block;
}

.width4rem {
  width: 4rem;
  display: inline-block;
}

.width5rem {
  width: 5rem;
  display: inline-block;
}

.width8rem {
  width: 8rem;
  display: inline-block;
}

.width10rem {
  width: 10rem;
  display: inline-block;
}

.width15rem {
  width: 15rem;
  display: inline-block;
}

.width20rem {
  width: 20rem;
  display: inline-block;
}

.labelPrefix {
  width: 5rem;
}

.longLabelPrefix {
  width: 8rem;
}

.blackText {
  color: black;
}

.greyText {
  color: $teliaDarkGrey;
}

.redText {
  color: $teliaDarkRed;
}

.greenText {
  color: $teliaDarkGreen !important;
}

.blueText {
  color: $teliaDarkBlue !important;
}

.tealText {
  color: $teliaDarkTeal !important;
}

.orangeText {
  color: $teliaOrange !important;
}

.darkPurpleText {
  color: $teliaDarkCorePurple !important;
}

.lineThrough {
  text-decoration: line-through solid darkred;
}

.rightText {
  text-align: right;
}

.leftText > * {
  text-align: left !important;
}

.centerText {
  text-align: center;
}

.inactive {
  color: $teliaDarkGrey;

  .list__link {
    color: $teliaDarkGrey;
  }
}

.rotateUp {
  -webkit-transform: rotate(-90deg);
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.noPadding {
  padding: 0 !important;
}

.paddingTop {
  padding-top: 1rem;
}

.paddingLeft {
  padding-left: 1rem;
}

.noMargin {
  margin: 0;
}

.noMarginTop {
  margin-top: 0;
}

.marginTop {
  margin-top: 1rem;
}
.marginTop2 {
  margin-top: 2rem;
}

.marginTopHalfRem {
  margin-top: 0.5rem;
}

.marginBottomHalfRem {
  margin-bottom: 0.5rem;
}
.marginBottom {
  margin-bottom: 1rem;
}
.marginBottom2 {
  margin-bottom: 2rem;
}

.marginLeft {
  margin-left: 1rem;
}

.marginRight {
  margin-right: 1rem !important;
}

.halfRightMargin {
  margin-right: 0.5rem;
}

.noMarginLeft {
  margin-left: 0 !important;
}

.hideTransition {
  transition: opacity ease 1s, max-height ease 1s;
  //transition: opacity ease 1s, padding ease 1s, margin ease 1s, width ease 1s, max-height ease 1s, font-size ease 1s;
}

.hideIt {
  max-height: 0;
  opacity: 0;
}

.badge {
  margin-left: 0.25rem;
  background: red;
  color: white;
  padding: 0.4rem;
  border-radius: 50rem;
  font-size: 0.8rem;
  vertical-align: super;
}

.default {
  color: $teliaDarkGrey;
}

.disabled {
  color: $teliaDarkGrey;
}

.purpleGreyBox {
  color: $teliaPurple !important;
  background-color: $teliaDarkGrey;
  display: inline-block;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border: $teliaPurple solid 1px;
  border-radius: 0.2rem;
}

.invalidPeriod {
  color: $teliaDarkRed !important;
}

.expiredPeriod {
  color: $teliaOrange !important;
}

.activePeriod {
  color: $teliaDarkGreen !important;
}

.futurePeriod {
  color: $teliaDarkBlue !important;
}

.forbidden {
  color: $teliaDarkRed;
  text-decoration: line-through;
  cursor: not-allowed;
}

.unavailable {
  color: $teliaDarkGrey2;
  cursor: not-allowed;
}

.pick {
  cursor: grabbing;
}

.highlight {
  background-color: $teliaDarkGreen;
}

.noWrap {
  white-space: nowrap;
}

//accessibility
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.hidden {
  visibility: hidden;
}

.code {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Courier New, monospace !important;
}

.minWidth {
  flex: 0 0 2em !important;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.noHover {
  pointer-events: none;
}

.menu__submenu {
  margin-top: 1px !important; // Fix for menu item with drop down not being perfectly aligned
}

.displayFlex {
  display: flex;
}

.flexGrow {
  flex: 1;
}

.flexBasis10 {
  flex-basis: 10%;
}

// Align customer selection in the middle between support and my profile headings
.menu__content-panel li:last-child {
  width: 100%;

  > button {
    margin-left: auto;
    margin-right: auto;
    padding: 0 0.5rem;
  }
}

.noTooltipPadding {
  * > button {
    margin: 0 !important;
  }
}

.envText {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 5px;
  color: $teliaDarkGrey2;

  // Hide the environment text when header is in mobile mode
  @media screen and (max-width: 1120) {
    display: none;
  }
}
