@import '../../../resources/styles/custom';

$borderWidth: 1px;

.settlement {
  position: relative;
  border: $teliaCorePurple $borderWidth solid;
  padding: 1rem;
}

.settlement.settlement--pdf {
  padding: 2rem;
  font-size: 0.95rem;
  border: 0 !important;
}

.settlement.settlement--pending {
  border-color: $teliaDarkRed;
}

.settlement.settlement--pending.settlement--adjusting {
  border-style: dashed;

  .settlement__approval {
    .container {
      color: $teliaDarkRed;
    }
  }
}

.settlement__approval {
  margin: -1rem -1rem 1rem -1rem;
  border-bottom: $teliaDarkRed $borderWidth dashed;

  .container {
    margin-bottom: 0;
  }
}

.settlement__header {
  position: relative;
  margin-bottom: 2rem;
}

.settlement__logo {
  position: absolute;
  top: 1rem;
  right: 1rem;

  img {
    width: 7rem;
  }
}

.settlement__fields {
  width: auto;
}

.settlement__field {
  padding: 0 0.4rem;
}

.settlement__field--label {
  color: $teliaDarkGrey2;
  padding-right: 1rem;
}

.settlement__fields--total {
  position: absolute;
  right: 0;
  bottom: 0;
}

.settlement__aggregate {
  margin: 3rem 0;
}

.settlement__list {
  padding: 0;
}

.settlement tr:last-of-type {
  border-bottom: 0;
}

.settlement__list th {
  background: $teliaGrey;
}

.settlement__list td,
.settlement__list th {
  margin: 0;
  padding: 0 0.4rem;
  border-radius: 0;
}

.settlement__list__item--sum {
  border-bottom: 0;

  td,
  th {
    border-top: $teliaDarkGrey 0.1rem solid;
    padding-bottom: 2rem;
  }
}

.settlement__list__item--total {
  td,
  th {
    padding-top: 1.5rem;
    //border-top: $teliaGrey 0.1rem solid;
    font-weight: bold;
  }
}

.settlement__list__body--padding__bottom > .settlement__list__item:last-child {
  td {
    padding-bottom: 1.5rem;
  }
}

.settlement__list__item--details {
  color: $teliaDarkGrey2;
}

.settlement__list__item--selected {
  background-color: rgba($teliaLightGreen, 0.25);
}

.settlement__adjustment__icon {
  cursor: pointer;
  width: 1.5rem;
  vertical-align: middle;
  margin: 0.25rem;
}

.settlement__adjustment__icon--delete {
  color: $teliaDarkRed;
}

.settlement__adjustment__icon--undo {
  color: $teliaDarkGrey2;
}

.settlement__adjustment__icon--add {
  color: $teliaPurple;
}

.settlement__adjustment--new {
  color: $teliaPurple;
}

.settlement__adjustment--deleted {
  color: $teliaDarkRed;
  text-decoration: line-through;
}

.settlement__adjustment__buttons {
  margin-top: 1rem;
}

.settlement__list__item--adjusting {
  td {
    vertical-align: top;
  }

  .textbox-with-label__input {
    border-color: $teliaPurple !important;
    color: $teliaPurple;
    padding: 0 0.5rem;
  }

  .settlement__adjustment__producttype {
    width: 11rem;
  }

  .settlement__adjustment__fee {
    width: 10rem;
    margin-bottom: auto;
  }

  .settlement__adjustment__text {
  }

  .settlement__adjustment__price {
    width: 6rem;
    float: right;

    input {
      text-align: right;
    }
  }
}

.settlement__show_details__button {
  cursor: pointer;
  color: $teliaPurple;
  text-decoration: underline;
  font-weight: normal;
  margin-left: 0.7rem;
}

@media screen and (max-width: 600px) {
  .settlement__logo {
    position: initial;
  }
  .settlement__list {
    .width0-on-small-only {
      width: 0;
      visibility: hidden;
      padding: 0 !important;

      span {
        width: 0;
        display: block;
        visibility: hidden;
      }
    }
  }
}

.settlement__total__sum {
  margin-left: 2.5rem;
  .settlement__total__sum--label {
    font-weight: lighter;
    color: $teliaDarkGrey2;
  }
  .settlement__total__sum--value {
    color: $teliaDarkTeal;
  }
}

.settlement__adjustment_add--tip {
  text-align: left;
  max-width: 230px;
  &:after {
    margin-left: 185px;
  }
}
