.Icon {
  font-size: inherit;
}

.Icon.rem1 {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
}

.Icon.rem1_25 {
  font-size: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
