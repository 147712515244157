@import '../../resources/styles/custom';

.breadcrumbs {
  margin: 0;
  display: none;
}

.breadcrumbs__breadcrumb {
  display: inline-block;
  margin-right: 1rem;
  background: url('../../../../../node_modules/@telia/styleguide/assets/icons-legacy/ico_linkarrow_purple.svg')
    no-repeat right center;
  background-size: 0.6rem;
  padding-right: 1.6rem;
}

.breadcrumbs__breadcrumb:last-child {
  background: none;
}

.breadcrumbs__breadcrumb__link {
  opacity: 0.75;
  cursor: pointer;
  border-bottom: rgba(0, 0, 0, 0) solid 0.05rem;
}

.breadcrumbs__breadcrumb__link:hover {
  opacity: 1;
  border-bottom: $teliaCorePurple solid 0.05rem;
}
